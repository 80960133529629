import * as React from 'react'
import Layout from '../components/layout'
import { Titulo } from '../components/titulo'
import { Container, Row, Col, Card } from 'react-bootstrap'
import retornoSeguro from '../docs/RetornoSeguro2021.pdf'


export default function retorno () {
    const data=[
        {id: '1', name: 'Retorno Seguro 2021', doc:retornoSeguro}
    ]
    return (
        <Layout>
            <Titulo title="Retorno seguro" />
            <Container>
                <Row>
                    <Col md={12}><h3>Documentos</h3></Col>
                    {data && data.map(e => (
                        <Col md={3} key={e.id}>
                        <Card className="bg-dark mt-4">
                            <Card.Body className="text-center">
                                <h4>{e.name}</h4>
                                <a href={ e.doc } target="_blank" rel="noreferrer" className="btn btn-primary btn-outlined btn-block">
                                    <span>Descargar</span>
                                </a>
                            </Card.Body>
                        </Card>
                    </Col>
                    ))}
                    <Col md={12} className="mt-5"></Col>
                    <Col md={12} className="mt-5"></Col>
                </Row>
            </Container>
        </Layout>
    )
}